import {BasicObject, SafeAny} from '@/types';
import {errorLog, http, indusWinHttp, quickHttp, sportsHttp} from '@/utils';
import {Linking} from 'react-native';
import Clipboard from '@react-native-clipboard/clipboard';
import {LotteryOrders} from '../mix-lottery/mix-lottery-service';
import globalStore from '@/services/global.state';
import {TGameCode} from '@/services/global.service';
export interface NormalType {
  orderStatus: string | '0' | '1' | '2' | '3';
  pageNo: number;
  pageSize: number;
  yearMonth?: string;
}

export interface ResType {
  totalPages: number;
  totalSize: number;
  content: SafeAny[];
}

export interface QuickResType {
  totalCount: number;
  records: SafeAny[];
}

export interface DigitOrderCodeItem {
  /** 号码盈利金额 */
  codeWinAmount: number;
  /** 用户购买的彩票 */
  indexCode: string;
  isWin: 1 | 0;
  /** 购买数量 */
  pickCount: number;
  pickAmount: number;
  /** 开奖号码 */
  wonCode: string;
  winAmount?: number;
}

export interface DigitOrderItem {
  createTime: string;
  drawTime: string;
  gameDrawTime: number;
  /** 订单号 */
  orderGroup: string;
  /** 彩票名 */
  timeName: string;
  totalAmount: number;
  winAmount: number;
  wonCode: string;
  shareAward: number;
  gameIconUrl: string;
  codeLists: DigitOrderCodeItem[];
}

export const getKerala = (data: NormalType & {lotteryType: 'KERALA'}) => {
  return http.post<null, SafeAny>('app/order/pay/listV2', data);
};

export const get3D = (data: NormalType) => {
  return http.post<null, SafeAny>('app/pick/order/listV2', data);
};

export const getQuick3D = (data: NormalType) => {
  return quickHttp.post<null, QuickResType>('games/quick3/order/list', {
    ...data,
    orderStatus: Number(data.orderStatus),
  });
};

export const getColor = (data: NormalType) => {
  return http.post<null, ResType>('app/redGreen/order/orderList', data);
};

export const getDice = (data: NormalType) => {
  return http.post<null, ResType>('app/diceThree/orderList', data);
};

export const getSatta = (data: NormalType) => {
  return http.post<null, SafeAny[]>('app/matka/order/list', data);
};

export const getScratchAndCasino = (
  data: {
    orderStatus: string | '0' | '1' | '2' | '3';
    pageNo: number;
    pageSize: number;
    endTime: string;
    startTime: string;
  } & {
    gameType: number;
  },
) => {
  return indusWinHttp.post<null, SafeAny[]>(
    'iGaming/igaming/getOrderList',
    data,
  );
};

export const getSports = (data: {
  orderStatus: string | '0' | '1' | '2' | '3';
  pageNo: number;
  pageSize: number;
  endTime: string;
  startTime: string;
}) => {
  return sportsHttp.post<null, {content: SafeAny[]}>('orderList', data);
};

export interface IShareInfo {
  gameCode: TGameCode;
  orderNo: string | undefined;
  threeOrderData?: SafeAny;
}
export const shareToChat = (data: IShareInfo) => {
  return http.post('app/share/shareGameToIm', data); // test
};

export interface IShareThirdData {
  platform: string;
  info: IShareInfo;
  shareToIM: Function;
}

export const setShareOrder = (data: BasicObject) => {
  return http.post('app/share/setShareOrder', data);
};

export const shareToThird = (
  platform: string,
  info: BasicObject,
  shareToIM: Function,
  sucCallback = () => {},
  failCallback = () => {},
) => {
  const userInviteCode = JSON.parse(
    localStorage.getItem('user') || '{}',
  ).userInviteCode;
  info.threeOrderData.data.userInviteCode = userInviteCode;
  if (platform === 'Chatroom') {
    try {
      const data = (info?.threeOrderData || {})?.data;
      const {gameIconUrl, gameName, gameType} = data;
      if (!gameIconUrl || !gameName || !gameType) {
        globalStore.globalTotal.next({
          type: 'warning',
          message: 'Data is loss',
        });
        return;
      }
      info.threeOrderData = JSON.stringify(info.threeOrderData);
      shareToIM(info).then(sucCallback).catch(failCallback);
    } catch (e) {
      console.log('error', e);
    }
  } else {
    try {
      const data = info?.threeOrderData || {};
      const baseUrl =
        window.location.protocol + '//' + window.location.host + '/bets-shard?';

      const link =
        baseUrl +
        dtoToQueryString({
          gameCode: info?.gameCode,
          orderNo: info?.orderNo,
          userId: data.userId,
        });
      Clipboard.setString(link);
      info.threeOrderData = JSON.stringify(info.threeOrderData);
      setShareOrder(info);
      switch (platform) {
        case 'Facebook':
          Linking.openURL('fb://messaging').catch(e => errorLog(e));
          break;
        case 'Telegram':
          Linking.openURL(`tg://msg_url?url=${encodeURIComponent(link)}`).catch(
            e => errorLog(e),
          );
          break;
        case 'Whatsapp':
          Linking.openURL('https://wa.me?text=' + link).catch(e => errorLog(e));
          break;
        case 'Instagram':
          Linking.openURL('instagram://media').catch(e => errorLog(e));
          break;
      }
    } catch (e) {
      globalStore.globalTotal.next({
        type: 'warning',
        message: 'Sharing failed, please try again!',
      });
    }
  }
};

export const dtoToQueryString = (obj: BasicObject) => {
  const keyValuePairs = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      keyValuePairs.push(
        encodeURIComponent(key) + '=' + encodeURIComponent(value),
      );
    }
  }
  return keyValuePairs.join('&');
};

export const getStateOrders = (data: {
  pageNo: number;
  pageSize: number;
  orderStatus: string | '0' | '1' | '2' | '3';
  yearMonth: string;
}) => {
  return quickHttp.post<null, LotteryOrders>('/api/loi/v1/order/list', {
    pageNo: data.pageNo,
    size: data.pageSize,
    orderStatus: data.orderStatus,
    yearMonth: data.yearMonth,
  });
};

export const getQuickStateOrders = (data: {
  pageNo: number;
  pageSize: number;
  orderStatus: string | '0' | '1' | '2' | '3';
  yearMonth: string;
}) => {
  return quickHttp.post<null, LotteryOrders>('/api/lqi/v1/order/list', {
    pageNo: data.pageNo,
    size: data.pageSize,
    orderStatus: data.orderStatus,
    yearMonth: data.yearMonth,
  });
};
