/* eslint-disable react-native/no-inline-styles */

import {View} from 'react-native';
import Text from '@basicComponents/text';
import {basicColor, flex, fontSize} from '@/components/style';
import {getVipRender} from '@/components/business/vip';
import React, {useEffect, useState} from 'react';
import LazyImage from '@/components/basic/image';
import {AxiosResponse} from 'axios';
import {SafeAny} from '@/types';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import theme from '@/style';
import {defaultHeaderImg} from '@/pages/me/me.variable';
export interface userInfoObj extends AxiosResponse {
  level: 7;
  userAvatar: 'https://lottery-india.oss-ap-south-1.aliyuncs.com/manager/d0ba712abfc6401eb366b860796c5e82.png';
  userInviteCode: 'ZPZVOW';
  userName: 'because';
}
const BetsShardUser = ({
  userInfo,
  notDrawn = false,
}: {
  userInfo: userInfoObj;
  notDrawn?: boolean;
}) => {
  const {calcActualSize} = useScreenSize();
  const [option, setOption] = useState<SafeAny>(null);
  useEffect(() => {
    setOption(getVipRender(userInfo?.level == null ? userInfo?.level : 0));
  }, [userInfo]);
  return userInfo === undefined ? (
    <></>
  ) : (
    <View
      style={[
        flex.flex,
        flex.row,
        flex.centerByCol,
        notDrawn ? flex.start : flex.centerByRow,
        {
          gap: calcActualSize(12),
        },
      ]}>
      <View
        style={[
          {
            width: calcActualSize(40),
            height: calcActualSize(40),
            overflow: 'hidden',
            borderRadius: calcActualSize(40),
            borderWidth: 1,
            borderColor: '#fff',
          },
        ]}>
        <LazyImage
          imageUrl={
            userInfo?.userAvatar ? userInfo?.userAvatar : defaultHeaderImg
          }
          occupancy="#0000"
          width={calcActualSize(40)}
          height={calcActualSize(40)}
        />
      </View>
      <View
        style={[
          flex.flex,
          flex.row,
          flex.centerByCol,
          {
            gap: calcActualSize(8),
          },
        ]}>
        <Text
          calc
          color={basicColor.white}
          fontSize={fontSize.m}
          blod
          fontFamily="fontInter">
          {userInfo?.userName}
        </Text>
        <Text
          calc
          style={[
            theme.position.rel,
            {
              top: calcActualSize(2),
            },
          ]}>
          {option.smallFn(20)}
        </Text>
      </View>
    </View>
  );
};
export default BetsShardUser;
