import {View, StyleSheet, Image} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import theme from '@/style';
import {useTranslation} from 'react-i18next';
import Won from '../won/won';
import LinearGradient from '@/components/basic/linear-gradient/linear-gradient';
import DigitBall from '@/common-pages/pick-digit/components/digit-ball';

const rightIcon = require('@components/assets/icons/me-list-item/right-icon.webp');

export interface DigitResultProps {
  wonCode?: string;
  codeLists?: {
    indexCode: string;
    pickAmount: number;
    pickCount: number;
    wonCode: string;
    winAmount?: number;
  }[];
}

const DigitResult = ({wonCode = '***', codeLists = []}: DigitResultProps) => {
  const {i18n} = useTranslation();

  const winList = React.useMemo(() => {
    return codeLists.filter(item => item.winAmount && item.winAmount > 0);
  }, [codeLists]);

  return (
    <View style={[]}>
      <View
        style={[
          theme.flex.row,
          theme.flex.centerByCol,
          theme.flex.between,
          styles.container,
          theme.margin.tops,
        ]}>
        <View style={[theme.flex.flex1]}>
          <Text fontFamily="fontInterBold">
            {i18n.t('bets-detail.label.drawRes')}：
          </Text>
        </View>
        <View
          style={[
            theme.flex.row,
            theme.flex.centerByCol,
            {gap: theme.paddingSize.xs},
          ]}>
          {['A', 'B', 'C'].map((item, _i) => (
            <DigitBall key={_i} digit={item} color={item} fontSize={16} />
          ))}
          <Text fontFamily="fontInterBold" size="medium">
            =
          </Text>
          {['A', 'B', 'C'].map((item, _i) => (
            <DigitBall
              key={_i}
              digit={wonCode[_i]}
              color={item}
              fontSize={16}
            />
          ))}
        </View>
      </View>
      {winList.length > 0 && (
        <View style={[theme.margin.tops, {gap: theme.paddingSize.s}]}>
          {winList.map((item, _i) => {
            const indexArr = item.indexCode.split('=');
            const codes = indexArr[1];
            const codeArr = codes.split('');
            const arr = [] as string[];
            const abcArr = indexArr[0]
              .split('')
              .map(p => ['A', 'B', 'C'].findIndex(pie => pie === p));
            abcArr.map((pie, i) => (arr[pie] = codeArr[i]));
            return (
              <Won
                key={_i}
                awardAmount={item.winAmount!}
                result={
                  <View style={[theme.flex.row]}>
                    <Text fontFamily="fontInterBold" size="medium">
                      {indexArr[0]}
                    </Text>
                    <Text fontFamily="fontInterBold" size="medium">
                      =
                    </Text>
                    <View style={[theme.flex.row]}>
                      {arr.map((pie, _ii) => (
                        <Text
                          key={_ii}
                          fontFamily="fontInterBold"
                          size="medium"
                          color={wonCode[_ii] === pie ? '#F15802' : '#48505D'}>
                          {pie}
                        </Text>
                      ))}
                    </View>
                    <View style={styles.tag}>
                      <Text
                        fontFamily="fontInterBold"
                        size="small"
                        color={theme.basicColor.white}>
                        x{item.pickCount}
                      </Text>
                    </View>
                  </View>
                }
                isBet={false}
              />
            );
          })}
        </View>
      )}
      <View
        // eslint-disable-next-line react-native/no-inline-styles
        style={[
          theme.flex.row,
          {flexWrap: 'wrap', gap: 8},
          theme.margin.tops,
          styles.codeList,
        ]}>
        {codeLists.map((item, _i) => (
          <View style={[styles.item]} key={_i}>
            <Text fontFamily="fontInterBold">{item.indexCode}</Text>
            <View style={styles.tag}>
              <Text
                fontFamily="fontInterBold"
                size="small"
                color={theme.basicColor.white}>
                x{item.pickCount}
              </Text>
            </View>
          </View>
        ))}
      </View>
      <LinearGradient colors={['#FFFFFF00', '#FFF']} style={[styles.over]}>
        <View style={[theme.flex.row, theme.flex.center, theme.padding.btml]}>
          <Text fontFamily="fontInterBold">View Details</Text>
          <Image
            source={rightIcon}
            style={[theme.icon.s, theme.margin.lefts]}
          />
        </View>
      </LinearGradient>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#F4F4F4',
    paddingVertical: 8,
    paddingHorizontal: 12,
  },
  item: {
    paddingHorizontal: 16,
    backgroundColor: '#F6F7FB',
    height: 36,
    borderRadius: 18,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tag: {
    backgroundColor: '#F15802',
    borderRadius: 4,
    paddingHorizontal: 4,
    marginLeft: 8,
    paddingVertical: 2,
  },
  over: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    paddingTop: 80,
  },
  codeList: {
    overflow: 'hidden',
    maxHeight: 36 * 3, // 每个item高度是36，这里最多展示3行
  },
});

export default DigitResult;
